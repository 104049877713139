<template>
  <div id="practicaltraining">
    <div class="cm-container">
      <breadcrumb></breadcrumb>
      <div class="nt">实训说明</div>
      <div class="tcontent">
        <div class="t-line">
          <span class="stitle">实训名称：</span>最多80个字符
        </div>
        <div class="t-line">
          <span class="stitle">附加课程：</span>《混凝土结构设计实操》
        </div>
        <div class="t-line">
          <span class="stitle">指导说明：</span
          >安装实训任务要求，打开本地软件执行图，并将最终成果上传到平台的相应任务中，实训完成的节点为页面中的”完成“。
        </div>
        <div class="t-line">
          <span class="stitle">附加课程：</span>《混凝土结构设计实操》
        </div>
      </div>
      <div class="nt">任务</div>
      <div class="task-content">
        <div class="task-text">据立体图绘制出机件的三件尺寸，并标注</div>
        <div class="task-img">
          <img src="@/assets/img/common/icon05.png" alt="" />
        </div>
        <div class="upload-c">
          <div class="ulc-line l01">
            <div class="ulc-fw">
              <div class="ul-title">成果上传：</div>
              <div class="ul-btn b01">
                上传成果
                <input type="file" @change="referenceUpload" multiple ref = "upinput">
              </div>
            </div>
            <div class="ul-btn b02" :class = "{disable:!filelist || !filelist.length}" @click="done">完成</div>
          </div>
          <div class="file-list" v-if = "filelist && filelist.length">
            <div class="foneline" v-for = "(item,index) in filelist" :key = "index">
              <div class="sicon">
                <img src="@/assets/img/common/icon06.png" alt="" />
              </div>
              <div class="m-text">{{item.name}}</div>
              <div class="e-btn b01" @click="deletf(index)">删除</div>
              <div class="e-btn b02">重新上传</div>
            </div>
            <!-- <div class="foneline">
              <div class="sicon">
                <img src="@/assets/img/common/icon06.png" alt="" />
              </div>
              <div class="m-text">7.梁柱截面尺寸估算及建模</div>
              <div class="e-btn b01">删除</div>
              <div class="e-btn b02">重新上传</div>
            </div>
            <div class="foneline">
              <div class="sicon">
                <img src="@/assets/img/common/icon06.png" alt="" />
              </div>
              <div class="m-text">7.梁柱截面尺寸估算及建模</div>
              <div class="e-btn b01">删除</div>
              <div class="e-btn b02">重新上传</div>
            </div>
            <div class="foneline">
              <div class="sicon">
                <img src="@/assets/img/common/icon06.png" alt="" />
              </div>
              <div class="m-text">7.梁柱截面尺寸估算及建模</div>
              <div class="e-btn b01">删除</div>
              <div class="e-btn b02">重新上传</div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="a-box" v-if = "isboxShow">
      <div class="b-mask"></div>
      <div class="b-content-c">
        <div class="bcc-title">
          <div class="t-text">实训结果</div>
          <div class="closeIcon" @click="hideb">
            &times;
          </div>
        </div>
        <div class="bcc-content">
          <div class="ttc">
            <span class = "fs01">实训成绩：</span>
            <span class = "fs02 pr">合格</span>
            <span class = "fs01">分数：</span>
            <span class = "fs03 pr">100分</span>
            <span class = "fs01">满分：</span>
            <span class = "fs03 pr">100分</span>
            <span class = "fs01">知识点：</span>
            <span class = "fs03 pr">3</span>
            <span class = "fs01">正确数：</span>
            <span class = "fs03 pr">3</span>
            <span class = "fs01">正确率：</span>
            <span class = "fs03 pr">100%</span>
            <span class = "fs01">实训次数：</span>
            <span class = "fs03 pr">3</span>
          </div>
          <div class="bcc">
            <div class="clc">
              <div class="c-title">实训分数曲线</div>
              <div class="c-content" id = "c01"></div>
            </div>
            <div class="crc">
              <div class="c-title">实训分数曲线</div>
              <div class="c-content" id = "c02"></div> 
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, onUnmounted,nextTick } from "vue";
import { useRouter } from "vue-router";
// import { useStore } from "vuex";
// import { setStorage } from "@/js/common";
import { makeChart } from "@/js/charts";
import breadcrumb from "@/components/breadcrumb";
export default {
  setup() {
    const router = useRouter();
    // const store = useStore();
    const state = reactive({
      filelist:[],
      upinput:null,
      isboxShow:false
    });
    const initc01 = function () {
      let data = [
          {
            name: "1",
            value: 60,
          },
          {
            name: "2",
            value: 70,
          },
          {
            name: "3",
            value: 47,
          }
        ];
        let xAxisData = data.map((v) => v.name);
        let yAxisData = data.map((v) => v.value);
          let option = {
          tooltip: {
            trigger: "axis",
            formatter: function (params) {
              let html = "";
              params.forEach((v) => {
                console.log(v);
                html += `<div style="color: #666;font-size: 14px;line-height: 24px">
                ${v.name}
                <span style="color:#000;font-weight:700;font-size: 18px">${v.value}</span>分`;
              });

              return html;
            },
            extraCssText:
              "background: #fff; border-radius: 0;box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);color: #333;",
          },
          grid: {
            top: 60,
            left: 20,
            right: 20,
            bottom: 30,
            containLabel: true,
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#3C3C3C",
                fontSize: 12,
              },
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            data: xAxisData,
          },
          yAxis: {
            type: "value",
            name: "分数",
            min: 0,
            max: 100,
            axisLabel: {
              textStyle: {
                color: "#C6C6C6",
                fontSize: 12,
              },
            },
            nameTextStyle: {
              color: "#C6C6C6",
              fontSize: 12,
              lineHeight: 40,
              padding:[0,0,0,-30]
            },
            splitLine: {
              lineStyle: {
                type: "dashed",
                color: "#eee",
              },
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            minInterval: 25,
          },
          series: {
            symbolSize: 5,
            color: '#059DF7',
            symbol: "circle",
            itemStyle: {
              normal: {
                color: "#fff",
                borderColor: '#059DF7',
                borderWidth: 1,
              }
            },
            // smooth: true,
            // showSymbol: false,
            name: "2019",
            type: "line",
            zlevel: 3,
            lineStyle: {
              normal: {
                color: "rgba(10, 179, 255,0.8)",
              },
            },

            data: yAxisData,
          },
        };
      makeChart("c01", option);
    };
    const initc02 = function () {
      let data = [
          {
            name: "1",
            value: 60,
          },
          {
            name: "2",
            value: 70,
          },
          {
            name: "3",
            value: 47,
          }
        ];
        let xAxisData = data.map((v) => v.name);
        let yAxisData = data.map((v) => v.value);
          let option = {
          tooltip: {
            trigger: "axis",
            formatter: function (params) {
              let html = "";
              params.forEach((v) => {
                console.log(v);
                html += `<div style="color: #666;font-size: 14px;line-height: 24px">
                ${v.name}
                <span style="color:#000;font-weight:700;font-size: 18px">${v.value}</span>%`;
              });

              return html;
            },
            extraCssText:
              "background: #fff; border-radius: 0;box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);color: #333;",
          },
          grid: {
            top: 60,
            left: 20,
            right: 20,
            bottom: 30,
            containLabel: true,
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#3C3C3C",
                fontSize: 12,
              },
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            data: xAxisData,
          },
          yAxis: {
            type: "value",
            name: "正确率",
            min: 0,
            max: 100,
            axisLabel: {
              textStyle: {
                color: "#C6C6C6",
                fontSize: 12,
              },
            },
            nameTextStyle: {
              color: "#C6C6C6",
              fontSize: 12,
              lineHeight: 40,
              padding:[0,0,0,-30]
            },
            splitLine: {
              lineStyle: {
                type: "dashed",
                color: "#eee",
              },
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            minInterval: 25,
          },
          series: {
            symbolSize: 5,
            color: '#059DF7',
            symbol: "circle",
            itemStyle: {
              normal: {
                color: "#fff",
                borderColor: '#059DF7',
                borderWidth: 1,
              }
            },
            // smooth: true,
            // showSymbol: false,
            name: "2019",
            type: "line",
            zlevel: 3,
            lineStyle: {
              normal: {
                color: "rgba(10, 179, 255,0.8)",
              },
            },

            data: yAxisData,
          },
        };
      makeChart("c02", option);
    };
    const methods = {
      hideb:() =>{
        state.isboxShow = false;
        router.go(-1)
      },
      done:()=>{
        if(state.filelist.length){
          state.isboxShow = true;
          nextTick(()=>{
            initc01();
            initc02();
          })
        }
      },
      deletf:(index)=>{
        console.log(index);
        state.filelist.splice(index,1)
      },
      referenceUpload:(e)=> {
        console.log(e,state.upinput.files);
        let files = state.upinput.files;
        if(files && files.length){
          for (let v of files){
            console.log(v)
            state.filelist.push(v);
          }
        }
        console.log(state.filelist)
      }
    };
    onMounted(() => {
      
    });
    onUnmounted(() => {});
    // provide('popfalse',state.popfalse)
    return {
      ...toRefs(state),
      ...methods,
    };
  },
  components: {breadcrumb},
};
</script>
<style lang="scss" scoped>
#practicaltraining {
  height: 100%;
  position: relative;
  .a-box{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index: 99;
    .b-mask{
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;

    }
    .b-content-c{
      position:absolute;
      top: 50%;
      left:50%;
      transform: translate(-50%,-50%);
      width:580px;
      height:367px;
      background: #fff;
      box-shadow: 0px 0px 7px #00000057;
      .bcc-title{
        padding:0 20px;
        height:40px;
        line-height: 40px;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        box-sizing: border-box;
        border-bottom:1px solid #EEEEEE;
        display: flex;
        justify-content:space-between;
        .closeIcon{
          font-size: 30px;
          cursor: pointer;
          color:#979797;
          font-weight: normal;
        }
      }
      .bcc-content{
        .ttc{
          padding:10px 20px 0;
          font-size: 14px;
          span{
            padding:0 0 10px;
            display: inline-block;
          }
          .pr{
            padding:0 30px 10px 0;
          }
        }
        .bcc{
          width:100%;
          height:250px;
          display:flex;
          .c-title{
            font-size: 14px;  
            color: #3C3C3C;
            padding:5px 20px 5px;
          }
          .c-content{
            flex-grow: 1;
          }
          .clc{
            width:50%;
            height:100%;
            display: flex;
            flex-direction: column;
          }
          .crc{
            width:50%;
            height:100%;
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
  .cm-container {
    width: 1200px;
    margin: 0 auto;
    background: #f3f3f3;
    padding: 0 0 100px;
  }
  .nt {
    font-size: 15px;
    font-weight: bold;
    color: #555555;
    margin: 20px 0 15px;
  }
  .tcontent {
    background: #fff;
    padding: 20px;
    .t-line {
      font-size: 14px;
      color: #333333;
      &:last-child {
        margin: 20px 0 0;
      }
      .stitle {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
      }
    }
  }
  .task-content {
    background: #fff;
    padding: 20px 20px 130px;
    .task-text {
      font-size: 14px;
      color: #555555;
    }
    .task-img {
      width: 431px;
      height: 264px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .upload-c {
      .ulc-line {
        display: flex;
        justify-content: space-between;
        .ulc-fw {
          display: flex;
          align-items: center;
          margin: 7px 0 0 0;
          .ul-title {
            font-size: 14px;
            font-weight: bold;
            color: #333333;
          }
        }
        .ul-btn {
          height: 30px;
          padding: 0 20px;
          font-size: 14px;
          color: #fff;
          line-height: 30px;
          background: #059df7;
          cursor: pointer;
          &.b01 {
            color: #fff;
            font-size: 14px;
            margin: 0 0 0 15px;
            position:relative;
            input{
              width:100%;
              height:100%;
              opacity: 0;
              position:absolute;
              top:0;
              left:0;
            }
          }
          &.b02 {
            &.disable {
              background: #c6c6c6;
              cursor: default;
            }
          }
        }
      }
      .file-list{
        .foneline{
          display: flex;
          align-items:center;
          margin:15px 0 0 ;
          .sicon{
            width: 20px;
            height: 24px;
            img{
              width:100%;
              height:100%;
              object-fit:contain;
            }
          }
          .m-text{
            font-size: 14px;
            color: #333333;
            min-width:310px;
            margin:0 0 0 10px;

          }
          .e-btn{
            height:20px;
            line-height: 20px;
            padding:0 10px;
            font-size: 10px;
            border: 1px solid #059DF7;
            margin:0 10px 0 0;
            cursor: pointer;
            color:#059DF7;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1600px) {
  #practicaltraining {
    .cm-container {
      width: 1400px;
      margin: 0 auto;
      background: #f3f3f3;
      padding: 0 0 100px;
    }
  }
}
</style>
